import { graphql } from 'gatsby';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Layout from '../components/layout';
import Headline, { HeadlineType } from '../components/headline';
import HeroSlideshow, { HeroSlide } from '../components/heroSlideshow';
import Slideshow, { SlideshowType } from '../components/slideshow';
import TextImage, { TextImageType } from '../components/textImage';
import Seo from '../components/seo';
import {
  Box,
  BoxProps,
  Container,
  Heading,
  HeadingProps,
  SimpleGrid,
  SimpleGridProps,
} from '@chakra-ui/react';

type ContentType =
  | (HeadlineType & {
      contentType: 'Template_Frontpage_Frontpagedata_Content_Headline';
    })
  | (SlideshowType & {
      contentType: 'Template_Frontpage_Frontpagedata_Content_Slideshow';
    })
  | (TextImageType & {
      contentType: 'Template_Frontpage_Frontpagedata_Content_TextImage';
    });

type Props = {
  data: {
    pageData: {
      title: string;
      template: {
        default_template: {
          defaultSection: {
            headline: string;
            content: string;
          }[];
        };
      };
    };
    seoData: {
      seo: {
        metaDescription: string;
        metaKeywords: string;
      };
    };
  };
};

const Frontpage = ({ data: { pageData, seoData } }: Props) => {
  const [initFlag, setInitFlag] = useState(false);
  const sections = pageData.template.default_template.defaultSection;

  useLayoutEffect(() => {
    setInitFlag(true);
  }, []);

  return (
    <Layout invertedFooter={true}>
      <Seo {...seoData.seo} title={pageData.title} />
      <Box {...headingContainerStyle}>
        <Container>
          <Heading {...headingStyle}>{pageData.title}</Heading>
        </Container>
      </Box>
      <Container>
        {sections.map((s, i) => (
          <SimpleGrid key={i} {...gridProps}>
            <Heading {...sectionHeadingStyle}>{s.headline}</Heading>
            <Box
              {...sectionBodyStyle}
              dangerouslySetInnerHTML={{ __html: s.content }}
            />
          </SimpleGrid>
        ))}
      </Container>
    </Layout>
  );
};

export default Frontpage;

const gridProps: SimpleGridProps = {
  columns: { md: 2 },
  columnGap: '40px',
  marginY: { base: '64px', md: '100px' },
};

const headingContainerStyle: BoxProps = {
  paddingY: '78px',
  backgroundColor: '#68744D',
};
const headingStyle: HeadingProps = {
  color: 'white',
  fontSize: { base: 'xl', md: '2xl' },
  lineHeight: 'shorter',
};
const sectionHeadingStyle: HeadingProps = {
  fontSize: { base: 'md', md: 'lg' },
};
const sectionBodyStyle: BoxProps = {
  fontSize: { base: 'sm', md: 'md' },
  sx: {
    a: {
      color: 'brand.orange',
      fontWeight: 'bold',
    },
  },
};

export const pageQuery = graphql`
  query DefaultPageQuery($id: String!) {
    pageData: wpPage(id: { eq: $id }) {
      title
      template {
        ... on WpDefaultTemplate {
          default_template {
            defaultSection {
              headline
              content
            }
          }
        }
      }
    }
    seoData: wpPage(id: { eq: $id }) {
      seo {
        metaDescription
        metaKeywords
      }
    }
  }
`;
